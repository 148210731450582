
import { defineComponent, onBeforeMount, onMounted,reactive,ref,toRefs } from "vue";
import Swiper from "./modules/swiper.vue";
import SubNav from "./modules/subNav.vue";
import Box from "./modules/box.vue";
import Sixbox from "./modules/SixBoxa.vue";
import FourBoxa from "./modules/FourBoxa.vue";
import Message from "@/components/message/Message.vue";
import axios from "@/utils/axios";
// import Vue3VideoPlayer from '@cloudgeek/vue3-video-player'
// import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css'

import * as echarts from "echarts";
import "../../../node_modules/echarts/map/china.js"; // 引入中国地图数据
export default defineComponent({
  name: "home",
  components: {
    Swiper,
    SubNav,
    Message,
    Box,
    Sixbox,
    FourBoxa,
    // Vue3VideoPlayer,
  },
  data() {
    return {
      ambassadorImg: require("@/assets/1new/赋能工程/赋能工程介绍.jpg"),
      videoImg: require("@/assets/1new/赋能工程/赋能工程视频.jpg"),
      personImg: require("@/assets/1new/赋能工程/5.jpg"),
      detailImg: require("@/assets/1new/赋能工程/赋能工程2.jpg"),
      article: {
        title: "“生涯规划师（赋能大使）”政策说明",
        sort: [
          {
            type: "赋能对象?",
            content: [
              "1、生涯规划师（赋能大使）：对生涯规划感兴趣，觉得有价值有意义，想从事生涯规划教育，但缺乏技能， 工具，平台，资源的个体老师和机构。（兼职，专职做高报和生涯的个体，想转型的教培机构，高报专职机构）",
              "2、中学生，家长：通过赋能大使给中学生赋能，帮助他们做好生涯规划，使生涯规划真正落地有效 ",
              "3、学校、教育局：有资源的赋能大使，能够对接学校和教育局资源，能够帮助赋能大使更好的服务学校，和 学校建立长期紧密的合作关系，获得优质生源和价值兑现",
              " 赋能要求：",
              "1、真正热爱和认可生涯规划教育，真心希望能够为学生家长提供帮助 ",
              "2、具有生涯规划基本能力，参加过新生涯培训的优先考虑（生涯规划师中级 TCT、生涯规划师高级 CDC、学情 分析师）",
              "3、有心理咨询、高考填报、生涯咨询经验 ",
            ],
          },
          {
            type: "如何赋能?",
            content: [
              "1、专业赋能：生涯教育是一个复杂的体系，心理，教育，测评，政策，学习力等等，从讲座到咨询，从选科 到高报，各个环节各个方面都需要专业的指导，这样才能够上手快，指导准，专业强。新生涯拥有强大专家研发团 队，多年的实践经验，完善的生涯体系，能够为老师赋能。",
              "2、工具赋能：开展生涯工作需要专业的生涯工具，海量数据查询，选科高报系统，生涯成长档案，咨询手册， 学习力手册，远程会客厅，生涯工具包等等，一系列专业工具和系统平台，能够让生涯教育更有专业，更高效。",
              "3、资源赋能：个体咨询师资源有限，除了选科、综评、高报等传统业务，其他业务开展难度较大。新生涯凭 借多年行业资源，依托立思辰上市企业优势，可以开展生涯研学，学科培优，竞赛组织等项目。同时，可以对接当 地学校和教育局，建立生涯实践基地，由当地赋能大使为学校提供生涯服务支持。 ",
              "4、运营赋能：如何通过生涯教育进行价值变现，做好家长群的互动转化，不同节点开展哪些活动，开展生涯 咨询的流程管理，这些都需要成熟的运营团队来指导扶持。新生涯有丰富多彩的讲座课程，有规范成熟的生涯运营 经验，有专业的运营团队来指导业务开展，我们会定期开展运营培训，专家也可以到当地现场指导，能够让赋能大 使事半功倍。 ",
              "5、平台赋能：生涯教育不是一个人单打独斗的事情，需要一群人相互交流相互学习相互支持。我们期待和赋 能大使们一起携手前行，建立一个新生涯老师生态圈，举办交流大会，实地观摩，学校考察，名家讲座，个体分享等多渠道多形式的交流平台，带动一百人，影响一万人，激发成就亿万人。",
            ],
          },
          {
            type: "赋能费用:",
            content: [],
          },
          {
            type: "赋能内容:",
            content: [
              "1、一套系统 生涯系列课堂+学科名师讲堂+生涯测评+选科指导+升学数据库+高考志愿填报+生涯档案管理+........... 个体咨询师：生涯云平台咨询师端口使用权三年，价格 2 万元，赠送 100 个学生端账号（598 元/个，100 个， 价值 6 万） 学生账号批量购买 100 元/个，200 个起批",
              "2、一套培训 运营+平台+业务培训，全年不少于 3 次，每次 2 天，免费参加（3000 元/场，3 次，价值 9000 元） 生涯规划师培训（中级、高级）、学情分析师，不限次参加，每次仅收 500 元场地费（中级 2980，高级 4980， 学情分析师 3980，价值 11000 元） 各类研讨会，交流会，参考考察，根据实际情况，只收取成本费。",
              "3、一个团队 市场运营+新媒体+研究院，全年各节点业务支持，活动策划，讲座支持，专业指导，让生涯业务更专业更落地 更有效。各节点讲座视频，生涯资料，在咨询师和校长端同步发布，赋能你的讲座和专业。 ",
              "4、一系列产品 选科指导+综评招生+高考填报+生涯研学+学培课程+....，还可以和学校对接，承接更大的项目，比如师资培训，生涯指导中心建设，生涯教材采购、生涯系统平台招投标等。资源进行充分开发，业务全年没断档，价值实现 最大化，形成业务闭环。 ",
            ],
          },
          {
            type: "补充说明:",
            content: [
              "1、赋能工程，侧重于给予生涯老师的技术，平台，工具，运营支持，不是招商代理，更不是加盟，不用也不 能使用新生涯的品牌，未经授权，不能以新生涯品牌进行招生宣传，否则我们将追究其法律责任。同时，我们不划 定赋能大使业务范围，也不授权某范围内独家经营。如果想成为我们的代理或加盟商，需要单独签订代理协议 ",
              "2、赋能工程，三年内能够赋能 2000 名生涯规划老师，进而辐射到更多的学校家长学生，让生涯之花开遍全国。",
              "3、生涯教育是一个新生事物，需要不断的成熟完善，他需要每个老师的参与和支持。我们希望赋能的老师也 能够不断的赋能这个生态这个平台，一起成长一起壮大，让这个生态良性发展，最终结出累累硕果。",
            ],
          },
        ],
      },
      article2: {
        title: "“生涯规划校（赋能机构）”政策说明",
        sort: [
          {
            type: "一、合作流程： ",
            content: [
              "1、统一提供：营业执照电子版、法人身份证正反面（拍照或扫描）、银行开户许可证、公司主体（名称）、 经营地址、业务具体执行人及联系电话。 ",
              "2、签订双方保障协议，甲方为立思辰新生涯，乙方为签约机构主体，双方各执一份，盖章签字存档。",
              "3、立思辰新生涯签发区域合作授权，取得本公司产品的该地经营权资格。 ",
            ],
          },
          {
            type: "二、签约权益:  ",
            content: [
              "1、享有立思辰新生涯品牌使用权。 ",
              "2、享有区域保护经营权。立思辰新生涯有规范而科学的区域保护制度，一旦签定独家经营合同，即可取得区域 保护权，享有相同等级的价格权益，获得长期的规模效益。 ",
              "3、享有合同优先权 合同续约优先权：双方合同期满，在没有违反合同规定的情况下，可优先获得续约，总部准予办理。",
              "4、享有VI形象设计使用权：免费享有公司VI形象、店面设计，总部给予传授和指导。",
            ],
          },
          {
            type: "三、运营支持:  ",
            content: [
              "1、建店支持",
              "新生涯是立思辰集团旗下品牌，是国内首家中学生涯规划教育一体化解决方案提供者，开创者及引领者，致力于中学生涯规划领域探究，构建中国本土化的K12全阶段生涯规划教育体系，全国预布点100家生涯规划特色的 规划学校。",
              "2、筹建支持",
              "总部与合作伙伴共同建立筹建时间表，各事件节点严格落实，避免工作遗漏； 运营部全程线上线下指导各项工作操 作技巧和注意事项。 ",
              "3、培训支持 ",
              "开业前，线上模块培训体系，全方位提升角色专业技能，开业后， 总部培训团队持续对岗位提供定期晋级和 管理培训服务。 ",
              "4、系统支持",
              "生涯云平台，可帮助机构实现生涯管理、生涯成长档案记录，为学生提供新高考选科查询、大学专业数据筛 选、高考志愿模拟，为教师和家长提供生涯课程与资源，保证生涯教育全面落实。 ",
              "5、运营支持 ",
              "新生涯运营团队提供综合的技术、培训、推广支持等专业运营管理服务，具体会由所在省份分公司、研究院及运营 部进行日常运营工作，严格按照总部的运营管理标准执行。 ",
              "6、市场支持 ",
              "总部市场团队定期进行定向推广和活动方案发布，支持全国授权机构招生获客；根据授权机构的需要，提供定制 化的市场宣传推广方案与指导。 ",
              "7、招生支持 ",
              "O2O运营模式，通过社群、活动、产品等互联网手段支持精准引流。",
              "8、谈判支持 ",
              "针对不同地域特点，设定研究院专家、市场区域经理，针对教育局或学校合作时，总部可以指派工作组协助谈 判签约，利用总部的服务内容和教研实力赢得尊重、促进合作。",
            ],
          },
          {
            type: "四、合作费用： ",
            content: [
              "1）校区云平台系统咨询师账号 5 套； 价格：5 万元； 作用：学生方案服务及管理；",
              " 2）学生端系统账号 500 套，单价 598 元 价格：30 万元； 作用：学习+选科+报考等；",
              "3）校长管理系统 1 套 价格：1 万元/套； 作用：员工和学员管理；",
              "4）校区生涯技能培训 价格：15 万元（限 5 人）；作用：测评解读、选科指导、学情分析、学习力、多元升学（强基/综评/高报等）",
              "5）区域独家在授权期限内开展校区三家以内不再支付总部授权费 ",
            ],
          },
          {
            type: "五、服务明细：  ",
            content: [],
          },
          {
            type: "六、遵循条件：  ",
            content: [
              "1、有独立的办公场地，展示立思辰新生涯服务项目； ",
              "2、有专业的咨询师和业务拓展人员各1名；",
              "3、定期参加总部现场或线上的市场技能培训、招生方法等相关培训； ",
              "4、及时向总部发送客户及市场信息反馈； ",
              "5、维护公司产品的知识产权不得随意泄露和使用.",
            ],
          },
        ],
      },
    };
  },
  methods: {
    goVideo() {
      window.open(
        "https://wx2981e99f79b0255b.wx.ckjr001.com/kpv2p/vvaz/?1614506476670=#/homePage/live/liveRoom?liveId=278469&SocialRoomId=337352&liveType=1&ttId=&tpId=&tlId=&ckFrom="
      );
    },
    

  },
  setup(){
     var mess = reactive({data:'video'})

      // 1 小新快讯/生涯资讯
        axios
        .get(
          "https://www.xsy985.com/api/news/news/?page=1&pagesize=2&news_type=1"
        )
        .then(function (response) {
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
      return{
        mess,
      }
  }
});
